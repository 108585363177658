@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/mixins';
@import '../../../../../assets/styles/typography';
@import '../../../../../assets/styles/fonts';


.scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: $ui-primary;
  cursor: pointer;
}