@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';


.landing {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("/front-static/images/home/background.svg");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  @include media(m-device) {
    padding: 40px;
  }

  & > h1 {
    width: 700px;
    margin-bottom: 31px;
    text-align: center;
    line-height: 1.2;
    font-size: 80px;
    font-weight: bold;
    color: $txt-primary;
    animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
    animation-delay: 1s;

    @include media(m-device) {
      width: 360px;
      font-size: 38px;
      line-height: 1.26;

    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media(m-device) {
      flex-direction: column;
    }

    &__primary {
      height: 64px !important;
      width: 114px;
      margin: 0 0 0 8px;
      font-size: $level4;
      animation: div-clip 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
      @include media(m-device) {
        height: 48px !important;
        width: 90vw;
        margin: 16px 0 0 0;
      }
    }
  }

}


.select {
  width: 423px;
  animation: div-clip 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
  @include media(m-device) {
    width: 90vw;
  }

  &__control {
    height: 64px !important;
    @include media(m-device) {
      height: 48px !important;
    }
  }

  &__menu {
    margin-top: 15px;
    height: 210px;

    @include media(m-device) {
      margin-top: 75px;
    }
  }

  &__category {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 8px;
      height: 18px;
    }

    &__mpp {
      margin-left: 8px;
      height: 28px !important;
      width: 55px;
    }

    &__new {
      right: 45px;
      position: absolute;

      @include media(s-device) {
        display: none;
      }
    }
  }
}

.scroll {
  position: absolute;
  bottom: 32px;
  animation: text-clip 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.modal {
  & > figure > img {
    height: 80%
  }

  &__overlay {
    z-index: 5000;
  }
}


.black {
  position: absolute;
  bottom: 0;
  right: 6%;
  width: 16px;
  height: 16px;
  animation: moveUpWithFade1 8000ms linear infinite forwards;
  overflow: hidden;
  @include media(m-device) {
    width: 14px;
    height: 14px;
    animation: none;
    top: 30%;
    right: 10%;
  }
}

.pink {
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 18px;
  height: 18px;
  animation: moveUpWithFade1 7000ms linear infinite forwards;
  overflow: hidden;
  @include media(m-device) {
    width: 14px;
    height: 14px;
    animation: none;
    bottom: 26%;
    left: 25%;
  }
}

.yellow_small {
  position: absolute;
  bottom: 0;
  right: 30%;
  width: 12px;
  height: 12px;
  animation: moveUpWithFade1 5500ms linear infinite forwards;
  overflow: hidden;
  @include media(m-device) {
    width: 16px;
    height: 16px;
    animation: none;
    bottom: 10%;
    left: 10%;
  }
}

.yellow_big {
  position: absolute;
  bottom: 0;
  left: 8%;
  width: 16px;
  height: 16px;
  animation: moveUpWithFade1 4500ms linear infinite forwards;
  overflow: hidden;
  @include media(m-device) {
    animation: none;
    top: 13%;
    left: 70%;
  }
}

.category {
  z-index: -1;
  width: 38px;
  height: 38px;
  @include media(m-device) {
    width: 24px;
    height: 24px;
  }

  &__left {
    position: absolute;
    bottom: 0;
    left: 25%;
    animation: moveUpWithFade1 4500ms linear infinite forwards;
    overflow: hidden;
    transform: rotate(-8deg);
    @include media(m-device) {
      animation: none;
      top: 20%;
      left: 15%;
    }

  }

  &__right {
    position: absolute;
    bottom: 0;
    right: 16%;
    animation: moveUpWithFade1 5000ms linear infinite forwards;
    overflow: hidden;
    transform: rotate(12deg);
    @include media(m-device) {
      animation: none;
      bottom: 20%;
      right: 16%;

    }
  }
}

@keyframes moveUpWithFade1 {
  from {
    bottom: -11%;
  }
  to {
    bottom: 100%;

  }
}

@keyframes text-clip {
  from {
    opacity: 0;
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}


@keyframes div-clip {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);

  }
}



